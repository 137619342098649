<template>
    <div class="modal" @click="close" v-if="path">
        <div class="modal-container">
            <img :src="path" alt="zoom" @click.stop :class="{ 'resp' : isResp }" />
        </div>
    </div>
</template>

<script>
export default {
    name : 'NftModal',
    props : ['path'],
    data() {
        return {
            isResp : false
        }
    },
    methods : {
        close() {
            this.$emit('close')
        }
    },
    mounted() {
        if(window.innerWidth < window.innerHeight) {
            this.isResp = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.75);
        z-index:999;
        cursor: pointer;
    }

    .modal-container {
        height: 95vh;
        width:90%;
        display: flex;
        justify-content: center;
        align-items: center;        
    }

    .modal-container img {
        height:100%;
        width:auto;
        cursor: initial;
    }

    .modal-container img.resp {
        width:100%;
        height:auto;
    }
</style>