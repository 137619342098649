<template>
    <div class="main-container">
        <!-- SECTION LOGOS -->
        <section class="section-logos">
            <h1 class="title-solo">CRYPTOVIRTUA 2024 NFT Collection</h1>
            <div class="col-title">
                <p>
                    <strong>Limited Edition. Only 11 exclusive NFT.</strong><br /><br />
                    Get one CRYPTOVIRTUA 2024 NFT.<br > Each NFT is unique and will give to its holder a dedicated benefit.
                </p>
                
            </div>

        </section>
        <!-- SECTION NFT -->
        <section class="nft-list section-box ">
          <NftItem
            v-for="(item, idx) in nftList" 
            :key="idx"
            :item="item"
          >
          </NftItem>
        </section>
    </div>
</template>

<script>
import NftItem from "./../components/NftItem.vue";

export default {
    name : "Nft",
    components : { NftItem },
    data() {
        return {
            nftList : [
                {
                    img : require('@/assets/nft/1_Gold.png'),
                    imgFull : require('@/assets/nft/1_Gold.png'),
                    name : "#1 • Gold",
                    text : "<strong>Holder benefit:</strong> Free ENTREPRISE booth on each CRYPTOVIRTUA edition",
                    price : 6,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522862959555510273",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/2_Silver.png'),
                    imgFull : require('@/assets/nft/2_Silver.png'),
                    name : "#2 • Silver",
                    text : "<strong>Holder benefit:</strong> Free PRO booth on each CRYPTOVIRTUA edition",
                    price : 4.5,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522864059067138049",
                    type : 1
                },
                                           {
                    img : require('@/assets/nft/3_Bronze.png'),
                    imgFull : require('@/assets/nft/3_Bronze.png'),
                    name : "#3 • Bronze",
                    text : "<strong>Holder benefit:</strong> Free BASIC booth on each CRYPTOVIRTUA edition",
                    price : 3,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522865158578765825",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/4_DiamondHands.png'),
                    imgFull : require('@/assets/nft/4_DiamondHands.png'),
                    name : "#4 • Diamond Hands",
                    text : "<strong>Holder benefit:</strong> Free SPONSOR status on each CRYPTOVIRTUA edition",
                    price : 15,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522866258090393601",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/5_Blockchain.png'),
                    imgFull : require('@/assets/nft/5_Blockchain.png'),
                    name : "#5 • Blockchain",
                    text : "<strong>Holder benefit:</strong> 3 stars on your virtual booth on the exhibit hall during the CRYPTOVIRTUA 2022 edition",
                    price : 1,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522867357602021377",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/6_Iron.png'),
                    imgFull : require('@/assets/nft/6_Iron.png'),
                    name : "#6 • Iron",
                    text : "<strong>Holder benefit:</strong> 2 stars on your virtual booth on the exhibit hall during the CRYPTOVIRTUA 2022 edition",
                    price : 0.5,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522868457113649153",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/7_Retro.png'),
                    imgFull : require('@/assets/nft/7_Retro.png'),
                    name : "#7 • Retro",
                    text : "<strong>Holder benefit:</strong> 1 star on your virtual booth on the exhibit hall during the CRYPTOVIRTUA 2022 edition",
                    price : 0.25,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522869556625276929",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/8_ToTheMoon.png'),
                    imgFull : require('@/assets/nft/8_ToTheMoon.png'),
                    name : "#8 • To The Moon",
                    text : "<strong>Holder benefit:</strong> CRYPTOVIRTUA backstage IRL visit in Aix-en-Provence (France) during 2 days with our teams (Accommodation & Subsistence costs included - Transportation not included)",
                    price : 3,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522870656136904705",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/9_Metaverse.png'),
                    imgFull : require('@/assets/nft/9_Metaverse.png'),
                    name : "#9 • Metaverse",
                    text : "<strong>Holder benefit:</strong> Free land (Size XL) in the upcoming SYNTHES'3D Metaverse project",
                    price : 10,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522871755648532481",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/10_Nature.png'),
                    imgFull : require('@/assets/nft/10_Nature.png'),
                    name : "#10 • Virtual Land",
                    text : "<strong>Holder benefit:</strong> Free land (Size L) in the upcoming SYNTHES'3D Metaverse project",
                    price : 4,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522872855160160257",
                    type : 1
                },
                                {
                    img : require('@/assets/nft/11_Glass.png'),
                    imgFull : require('@/assets/nft/11_Glass.png'),
                    name : "#11 • Glass",
                    text : "<strong>Holder benefit:</strong> Free land (Size M) in the upcoming SYNTHES'3D Metaverse project",
                    price : 2,
                    link : "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/31784678456151719267153661767514987065931977771157158399479522873954671788033",
                    type : 1
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .section-logos {
        background-image: url('./../assets/bg-home-participate.png');
        background-repeat: no-repeat;
        background-position: top right;
        padding-bottom:80px;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:18px;
        line-height: 1.5em;
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;
    }

    .col-title p strong {
        font-size:25px;
    }

    .nft-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
</style>