<template>
    <div :class="['nft-item', { 'highlight' : item.highlight }]" v-if="item" >
        <img :src="item.img" :alt="item.name" class="nft-img" @click="openModal" />
        <h4 v-html="item.name"></h4>
        <p class="desc" v-html="item.text"></p>
        <div class="price-container" v-if=" item.price">
            <img src="./../assets/ether.svg" class="ether-img" alt="ether"   />
            <p> {{ item.price }} </p>
        </div>
        <template v-if="item.type === 1">
            <a :href="item.link" target="_blank" class="btn">Buy it on OpenSea</a>
        </template>
        <template v-else-if="item.type === 2">
                <a :href="item.link" v-if="item.link" target="_blank" class="btn btn-auction" v-tooltip="'Coming soon'">Live Auction!<br />Participate now!</a>
                <p class="auction" v-else>Stay tuned!</p>
        </template>
        <template v-else>
            <p class="auction">Not for sale (Marketing purposes)</p>
        </template>
        <NftModal
            v-if="displayModal"
            :path="nftSelected"
            v-on:close="closeModal"
        >
        </NftModal>
    </div>

</template>

<script>
import NftModal from './NftModal'

export default {
    name : "NftItem",
    props : ['item'],
    components : { NftModal },
    data() {
        return {
            displayModal : false,
            nftSelected : null
        }
    },
    methods : {
        openModal() {
            this.nftSelected = this.item.imgFull
            this.displayModal = true
        },
        closeModal() {
            this.ntfSelected = null
            this.displayModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
    .nft-item{
        width:23%;
        margin:40px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nft-item.highlight {
        width:365px;
    }

    .nft-img {
        width:90%;
        margin-bottom:20px;
        background:lightgray;
        cursor: pointer;
        transition:0.5;
        opacity: 1;
    }

    .nft-img:hover {
        opacity: 0.8;
    }

    h4 {
        margin:0;
        font-size:20px;
        font-weight:700;
        text-align: center;
        color:$text-color;
        letter-spacing: 1.5px;
    }

    .btn {
       font-size: 16px;
       width:180px;
       height:50px;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 4px;
       font-weight: 500;
       color:#fff;
       border:1px solid $main-color;
       cursor: pointer;
       transition:0.5s;
       text-decoration: none;
       margin-top:0;
       line-height:1.2em;
       text-align: center;
       background: $main-color;
    }

    .btn:hover {
        background:$secondary-color;
        border: 1px solid $secondary-color;
    }

    .btn-auction {
        color:#fff;
        background:#e31e24;
        border: 1px solid #e31e24;
    }

    .btn-auction:hover {
        background:#254285;;
        border: 1px solid #254285;;
    }

    .price-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ether-img {
        height:20px;
        width:auto;
        margin-right:5px;
    }

    p {
        font-size:20px;
        line-height:1.5em;
    }

    .auction {
        margin-top:10px;
        font-size: 15px;
        text-align: center;
    }

    .desc {
        font-size:18px;
        text-align: center;
        color:$text-color;
        padding:10px;
        border:1px solid $text-color;
        margin-bottom: 0;
    }

    @media screen and (max-width:1250px) {
        .nft-item{
            width:29%;
        }
        .nft-item.highlight {
            width:365px;
        }
    }

    @media screen and (max-width:768px) {
        .nft-item{
            width:46%;
        }
        .nft-item.highlight {
            width:365px;
        }
    }

    @media screen and (max-width:500px) {
        .nft-item{
            width:96%;
        }

        
    }

</style>